<template>
  <div class="w-full group flex flex-col rounded-lg border-solid">
    <div
      class="flex flex-col items-start sm:items-center sm:flex-row py-3 px-3 w-full justify-between"
    >
      <div class="flex w-full items-start justify-between sm:mt-0">
        <div class="flex flex-col w-full items-start mx-3">
          <span
            v-if="cv"
            data-cy="upload-cv-success-filename"
            class="copy-bold break-all text-grey-900"
          >
            {{ cv.filename }}
          </span>
          <span class="text-labelling-inactive text-grey-800">
            Added: {{ dateAdded }}
          </span>
        </div>
      </div>
      <div
        class="flex items-start w-full mt-4 flex-row space-x-3 sm:mt-0 sm:w-auto"
      >
        <icon-with-tooltip tooltip-text="View/Download">
          <a
            :href="cv.location"
            class="material-icons-outlined bg-sage-50 rounded-full text-sage-700 p-3"
          >
            file_download
          </a>
        </icon-with-tooltip>
        <button
          @click="$emit('select')"
          class="button-secondary text-cta-secondary w-full sm:w-28"
        >
          Select
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { format } from "date-fns";
import IconWithTooltip from "@/components/IconWithTooltip.vue";

export default {
  components: {
    IconWithTooltip,
  },

  props: {
    cv: {
      type: Object,
      required: true,
    },
  },

  computed: {
    dateAdded() {
      return format(new Date(this.cv.created_at), "MMMM d, yyyy, h:mma");
    },
  },
};
</script>
