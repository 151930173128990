<template>
  <div
    v-for="(optionText, index) in interestOptions"
    class="radio-answer mb-4"
    :key="'radio-answer-' + index"
  >
    <input
      :id="'interested-in-' + index"
      name="interested-in"
      type="radio"
      :value="index"
      @input="updateInterestLevel"
      v-model="selectedInterestLevel"
    />
    <label :for="'interested-in-' + index">
      {{ optionText.split(",")[0] }}
      <p style="font-weight: 400; font-size: 14px">
        {{ optionText.split(",")[1] }}
      </p>
    </label>
  </div>
  <div v-if="!levelSelected && showValidation" class="pt-3">
    <error-message message="Please select an option." class="mb-0" />
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

const DEFAULT_INTEREST_LEVEL = 1;

export default {
  name: "InterestLevelRadioButtons",

  components: { ErrorMessage },

  props: {
    interestLevel: {
      type: Number,
    },
    interestOptions: {
      type: Array as PropType<string[]>,
    },
  },

  computed: {
    levelSelected() {
      return this.selectedInterestLevel !== null;
    },
  },

  emits: ["update-interest-level"],

  data() {
    return {
      selectedInterestLevel: null,
      showValidation: false,
    };
  },

  created() {
    this.selectedInterestLevel = isNaN(this.interestLevel)
      ? DEFAULT_INTEREST_LEVEL
      : this.interestLevel;
    this.$emit("update-interest-level", this.selectedInterestLevel);
  },

  methods: {
    updateInterestLevel(event) {
      this.selectedInterestLevel = Number(event.target.value);
      this.$emit("update-interest-level", this.selectedInterestLevel);
    },

    async isValid() {
      this.showValidation = true;

      if (!this.levelSelected) {
        await this.$nextTick();
        const errorElement =
          document.getElementsByClassName("error-message")[0];

        errorElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.option-btn-container {
  display: flex;
  justify-content: center;
}

.option-btn {
  margin: 1rem;
  width: 5rem;
}

@media only screen and (max-width: 375px) {
  .icon {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .radio-group {
    padding: 0 1rem;
  }
}

.radio-answer {
  --grey-700: #565958;
  --grey-900: #242726;
  --sage-600: #3c6955;
  --sage-50: #f5faf7;
  --grey-50: #fafafa;
  display: block;
  position: relative;
  min-height: 40px;
  margin-bottom: 10px;
  padding-left: 40px;
  border-radius: 18px;
}

@media only screen and (max-width: 640px) {
  .radio-answer {
    background-color: var(--grey-50);
  }
}

.radio-answer input[type="radio"] {
  cursor: pointer;
  position: absolute;
  left: -2px;
  width: 44px;
  height: 44px;
  margin: 0;
  opacity: 0;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 1;
}
.radio-answer div {
  padding: 10px 10px;
}
.radio-answer label {
  touch-action: manipulation;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 10px;
  cursor: pointer;
  line-height: 1.25;
  color: var(--grey-700);
}

@media only screen and (max-width: 640px) {
  .radio-answer label {
    width: 100%;
  }
}

.radio-answer label::before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: 7px;
  width: 26px;
  height: 26px;
  border: 2px solid currentcolor;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.radio-answer label::after {
  content: "";
  left: 12px;
  width: 0;
  height: 0;
  border: 8px solid currentcolor;
  border-radius: 50%;
  opacity: 0;
  background: currentcolor;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.radio-answer input[type="radio"]:checked + label {
  text-shadow:
    -0.03ex 0 currentColor,
    0.03ex 0 currentColor;
}

.radio-answer input[type="radio"]:checked + label::before {
  border-color: var(--sage-600);
}

.radio-answer input[type="radio"]:checked + label::after {
  opacity: 1;
  border-color: var(--sage-600);
}

@media only screen and (max-width: 640px) {
  .radio-answer.selected {
    background-color: var(--sage-50);
  }
}
</style>
