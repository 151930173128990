<template>
  <upload-cv-modal v-if="modalOpen" @close-modal="modalOpen = false" />
  <div class="flex justify-between flex-col md:flex-row">
    <h4 class="hidden md:block text-title-h2 w-full">Your CVs</h4>
    <button
      @click="modalOpen = true"
      class="button-secondary text-cta-secondary whitespace-nowrap flex items-center justify-center mt-5 xs:mt-0"
    >
      <span class="material-icons-round mr-2 text-sm"> cloud_upload</span>
      Upload new CV
    </button>
  </div>
  <role-held-form-skeleton v-if="isLoading" />
  <div v-else>
    <cv-none v-if="hasNoCvs" class="mt-5" />
    <div v-else class="mt-5 space-y-3">
      <div v-for="cv in cvs" :key="cv.id" class="flex flex-col">
        <cv :cv="cv" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Cv from "@/components/account/cv/Cv.vue";
import CvNone from "@/components/account/cv/CvNone.vue";
import UploadCvModal from "@/components/account/cv/UploadCvModal.vue";
import useCvs from "@/composables/useCvs";
import RoleHeldFormSkeleton from "@/components/miniCv/RoleHeldFormSkeleton.vue";

export default {
  name: "YourCvs",

  components: {
    UploadCvModal,
    Cv,
    CvNone,
    RoleHeldFormSkeleton,
  },

  setup() {
    const { individualCvs: cvs, getIndividualCvs } = useCvs();
    return { cvs, getIndividualCvs };
  },

  data() {
    return {
      modalOpen: false,
      isLoading: true,
    };
  },

  async created() {
    await this.getIndividualCvs();
    this.isLoading = false;
  },

  computed: {
    hasNoCvs() {
      return !this.cvs.length;
    },
  },
};
</script>
